@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  & button {
    border-radius: 4px;
  }
  margin-top: 15px !important;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  color: #025196;
  font-weight: 200;
  border-bottom: 1px solid #025196;

  &:hover {
    text-decoration: none;
  }
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.loginForm {
  border: 1px solid #023462;
  border-radius: 8px;
  padding: 5px 20px;
  position: relative;
  margin-bottom: 30px;
  & label {
    position: absolute;
    background-color: white;
    padding-left: 1px;
    padding-right: 1px;
    margin-top: -17px;
    font-size: 12px;
    font-weight: 300;
    color: #b9b9b9;
  }
  & input {
    border-bottom: none;
    font-weight: 200;
  }
  & div {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    position: absolute;
    white-space: nowrap;
  }
}
.demoError {
  border: 1px solid #f24040 !important;
  & label {
    color: #f24040;
  }
}
