@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  /* @media (--viewportMedium) {
    margin-top: 32px;
  } */
  @media (max-width: 575px) {
    flex-direction: column;
  }
}

.nameRoot {
  /* width: calc(50% - 9px); */
  /* border: 1px solid #023462; */
  /* border-radius: 8px; */
  flex-basis: 48%;
  /* padding: 4px 40px 0 5px; */
  
  position: relative;
  @media (max-width: 575px) {
    margin-bottom: 25px;
  }
  & label {
    position: absolute;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: -15px;
    font-size: 12px;
    font-weight: 300;
    color: #b9b9b9;
    margin-left: 12px;
  }
  & input {
    border-bottom: none;
    border: 1px solid #023462;
    border-radius: 8px;
    padding: 8px 12px;
    height:50px;
    font-size: 12px;
    font-weight: 300;
    background-color: white;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 36px;
    @media (max-width: 575px) {
      padding: 7px;
    }
  }
  & div {
    font-size: 12px;
    font-weight: 300;
    margin-top: 10px;
    position: static;
    white-space: normal;
    word-break: break-all;
    line-height: 18px;
    margin-bottom: 0 !important;
    /* text-wrap: wrap;
    line-height: 15px; */
  }
}
.nameRoot:nth-last-child(1) {
  @media (max-width: 575px) {
    margin-bottom: 0;
  }
}
.lastNameRoot {
  /* width: calc(66% - 9px); */
  border: 1px solid black;
  border-radius: 4px;
  flex-basis: 48%;

  & input {
    border-bottom: none;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  /* margin-top: 38px !important; */
  @media(max-width:767px){
    margin-top:10px !important;
  }
  @media(max-width:575px){
    margin-top:10px !important;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  & button {
    border-radius: 4px;
  }
  margin-top: 30px !important;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.radioButton {
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  /* background-color: white; */
  /* padding-left: 2px;
  padding-right: 2px;
  margin-top: -15px;
  font-size: 12px;
  font-weight: 300;
  color: #b9b9b9;
  margin-left: 12px; */

  /* flex-direction: column; */

  /* height: 49px; */
  @media (max-width: 575px) {
    flex-direction: column;
  }
  & > div{
    & > span:nth-child(2){
      padding: 8px 12px;
      height: 50px;
      & > label{
        padding-top:0;
        padding-bottom:0;
        line-height: 10px;
        & > span{
          padding-top:0;
          padding-bottom:0;
        }
      }
    }
  }
}
.buttonInput {
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
  position: relative;
  /* & span {

  } */
  /* align-items: center; */
  @media (max-width: 575px) {
    margin-bottom: 30px;
  }
}
.buttonInput:nth-last-child(1) {
  @media (max-width: 575px) {
    margin-bottom: 0;
  }
}
.radioLabel {
  position: absolute;
  top: -16px;
  left: 12px;
  background-color: white;
  z-index: 1;
  padding: 0 2px;
  height: 25px;
  font-size: 12px;
  font-weight: 300;
  color: #b9b9b9;
}
.entrepreneur {
  border: 1px solid #023462;
  border-radius: 8px;
  padding: 8px 12px;
  height: 50px;
  & label {
    @media (max-width: 575px) {
      padding: 6px 0;
    }
    & > span:nth-child(2) {
      font-size: 12px;
      font-weight: 300;
      margin-top:0 !important;
      margin-bottom:0 !important;
    }
  }
}
.contactInfo {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* border: 1px solid #023462;
  border-radius: 8px; */
  /* padding: 0 40px 0 5px;
  height: auto; */
  & span {
    position: absolute;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    margin-top:2px;
    font-size: 12px;
    font-weight: 300;
    color: #b9b9b9;
    margin-left: 12px;
    z-index: 1;
    line-height: 16px;

  }

  & div {
    & > div:nth-child(3) {
      border-bottom: none !important;
      background-color: transparent;
      margin-top: 10px;
      /* height: auto; */
      & > div{
        width: auto !important;
       height: 100% !important;;
       padding: 0 !important;
       & > div{
        margin-left: 8px;
       }
      }
      & > ul{
        width: 460px !important;
        & > li:nth-child(1){
          & input{
            margin-left: 0 !important;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    /* margin-top: 35px; */
    margin-top: 0;
  }
  & > div {
    & > div:nth-child(3) {
      & > ul {
        @media (max-width: 767px) {
          width:550px!important;
          /* margin:0 15px !important; */
        }
        @media (max-width: 575px) {
          width:450px!important;
        }
        @media (max-width: 480px) {
          width:390px!important;
        }
        @media (max-width: 420px) {
          width:370px!important;
        }
        @media (max-width: 390px) {
          width:360px!important;
        }
        @media (max-width: 360px) {
          width: 290px!important;
        }
        & > li:nth-child(1){
         padding:10px 10px 6px 10px;
        }
      }

    }
    & > input{
      height: 50px !important;
      border: 1px solid #023462;
      border-radius: 8px !important; 
      padding: 0px 12px;
      border-bottom: 1px solid #023462 !important;
    }

  }

}
.contactInfo > div > input {
  /* border-bottom-width: 0px !important; */
  padding-top: 0 !important;
  margin-top: 2px !important;
  font-size: 12px;
  font-weight: 300;
}
.errorContacttext {
  font-size: 12px;
  font-weight: 300;
  color: red;
  position: absolute;
}
.emailandpassword {
  margin-top: 35px;
  @media (max-width: 575px) {
    /* margin-top: 25px !important; */
  }
  & input {
    border: 1px solid #023462;
    border-radius: 8px;
    padding:0 12px !important;
    height: 50px !important;
    /* border-bottom: none; */
    font-size: 12px;
    font-weight: 300;
    @media (max-width: 575px) {
      padding: 8px 0;
    }
  }
  & label {
    position: absolute;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: -12px;
    font-size: 12px;
    font-weight: 300;
    color: #b9b9b9;
    margin-left: 12px;
  }
  & div {
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
    position: absolute;
    white-space: unset;
    word-break: break-all;
    left: 60px;
    right: 0;
    /* padding: 0 15px; */
    @media(max-width:767px){
      position: static;
      padding: 0;
    }
  }
}
.demoError {
  border: 1px solid #f24040 !important;
  & label {
    color: #f24040;
  }
}
.error {
  font-size: 12px;
  font-weight: 300;
  color: red;
}
.PhoneRoot{
  margin-top: 0;
}
.invalidName {
  & label {
    color: var(--failColor);
  }
  & input,
  & textarea {
    border-color: var(--failColor) !important;
  }
}